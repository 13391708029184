import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import { Section, Container } from '@components/global';

const About = () => (
  <StaticQuery
    query={graphql`
      query {
        art_fast: file(
          sourceInstanceName: { eq: "art" }
          name: { eq: "fast" }
        ) {
          childImageSharp {
            fluid(maxWidth: 760) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }

        art_learn: file(
          sourceInstanceName: { eq: "art" }
          name: { eq: "learn_yourself" }
        ) {
          childImageSharp {
            fluid(maxWidth: 760) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }

        art_ideas: file(
          sourceInstanceName: { eq: "art" }
          name: { eq: "ideas" }
        ) {
          childImageSharp {
            fluid(maxWidth: 760) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    `}
    render={data => (
      <Section id="about">
        <Container>
          <Grid>
            <div>
              <h2>Basic Principles of Investing</h2>
              <p>
                The Vanguard Group completed thorough research on the importance
                of asset allocation to the variability of returns. Surprisingly,
                around 88% of the variation in the returns of a broadly
                diversified portfolio resulted from the choice of asset class.
                Things like ensuring you time the market correctly and picking
                the right stocks, had a minimal impact on the variability of
                returns over time.1
                <br />
                <br />
                So what does this mean? It means that it is very important that
                we get the asset allocation correct. The individual stock choice
                is not as important when investing in diversified portfolios.
                Our questionnaire will ensure you have the right investment
                portfolio, given your risk tolerance and level of returns you
                desire. Answering the questions as honestly as possible will
                ensure we offer advice tailored for you.
              </p>
            </div>
            <Art>
              <Img fluid={data.art_fast.childImageSharp.fluid} />
            </Art>
          </Grid>
          <Grid inverse>
            <Art>
              <Img fluid={data.art_learn.childImageSharp.fluid} />
            </Art>
            <div>
              <h2>Diversification</h2>
              <p>
                Picking individual stocks is a difficult job to master. Most do
                it poorly, and the few that can do it well, usually struggle
                over a long period of time. I mean, we would love to have bought
                shares of Apple in the mid 90’s and made a fortune. But I don’t
                have a genie that can foresee the future, and chances are those
                who pick stocks don’t either. Some get lucky, some have the
                skills to do it, but for everyone else – investing in a
                diversified portfolio is best. Although buying Apple 30 years
                ago would have been nice, there is another 50 stocks we’re glad
                we didn’t buy. Whenever it comes to investing, there’s always a
                chance of losing money. Any company, at any time can shock the
                market with an announcement no one was expecting.
              </p>
              <br />
              <h3> “Don’t look for the needle. Just buy the haystack.”</h3>
              <br />
              <p>
                {' '}
                We believe the best way to invest is buying hundreds or
                thousands of securities at a time. This may sound impossible and
                unrealistic for someone who doesn’t have countless spare hours
                every day. However, this can be done through an exchange-traded
                fund (ETF). An ETF is a diversified group of stocks or bonds all
                packaged together. There are numerous types of ETF’s – some
                might buy underlying securities to mimic the returns of an
                index, others may have exposure to specific industries, or some
                may stick to emerging markets. There are actually hundreds of
                different ETF’s listed on the ASX with many different underlying
                portfolios. You can buy the ETF like you would a normal stock,
                and therefore you can by hundreds or thousands of securities
                within a few clicks.
              </p>
            </div>
          </Grid>
          <Grid>
            <div>
              <h2>How Inflation Can Erode Your Savings</h2>
              <p>
                Most people have heard the word inflation – but what effect does
                it have on your hard-earned savings? Inflation is a term used to
                reflect the rise in prices for an economy - if inflation rises
                by 2% per year then something that cost $100 will be $102 the
                year after. So, if my bank account is giving me a 1% return on
                my savings, what does a 2% rise in inflation mean?
                <br />
                <br />
              </p>
              <p>
                The Reserve Bank of Australia’s target inflation rate is between
                2-3%. In the graph above we have assumed the lower end of 2%
                (which means that the real deterioration of savings would
                actually probably be greater). Currently banks are offering
                about 1% interest on savings accounts. As shown in the graph,
                you can see the deterioration of $100,000 compared to inflation
                over time. This means that people who save their money in a bank
                are actually losing money in ‘real terms’, because the rate of
                inflation is higher than interest rates. Over longer time
                periods, this can be detrimental to your savings which you have
                worked so hard for.
              </p>
              <p>
                The graph highlights the importance of investing. Over 30 years,
                $100,000 has grown to $540,000. This was assuming the savings
                were put in a portfolio with a growth rate of 6% per year -
                which is a fairly conservative estimate. This is hugely
                different to sticking the money in a bank and hoping your bank
                will raise interest rates. Obviously the investment portfolio
                won’t be growing at a smooth rate like that in the graph. But,
                the overall trend of holding a diversified investment portfolio
                for a long period of time will most likely look to be something
                similar.
              </p>
            </div>
            <Art>
              <Img fluid={data.art_ideas.childImageSharp.fluid} />
            </Art>
          </Grid>
        </Container>
      </Section>
    )}
  />
);

const Grid = styled.div`
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-gap: 40px;
  text-align: right;
  align-items: center;
  justify-items: center;
  margin: 24px 0;

  ${props =>
    props.inverse &&
    `
    text-align: left;
    grid-template-columns: 2fr 3fr;
  `}

  h2 {
    margin-bottom: 16px;
  }

  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    text-align: left;
    margin-bottom: 96px;

    &:last-child {
      margin-bottom: 24px;
    }

    ${props =>
      props.inverse &&
      `
        ${Art} {
          order: 2;
        }
    `}
  }
`;

const Art = styled.figure`
  margin: 0;
  max-width: 380px;
  width: 100%;
`;

export default About;
